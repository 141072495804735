@use "../global" as g;

/* ==========================================================================
  footer
========================================================================== */


/*# top
/*===========================================*/
.l-footer {
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: g.$col-black;

  @include g.mq(md) {
    position: absolute;
    bottom: 0;  
  }

  .l-footer-copy {
    color: #fff;
    font-size: 10px;
  }
}


/*# 下層
/*===========================================*/
.l-u_footer {
  background-color: g.$col-black;
  
  .l-u_footer-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 180px;    
  }
  .l-u_footer-logo {
    width: 94px;
  }
  .l-u_footer-copy {
    color: #fff;
    margin-top: 10px;
    font-size: 12px;
    text-align: centers;
  }
}