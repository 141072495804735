@use "../global" as g;

/* ==========================================================================
  top
========================================================================== */

.c-top-menu {
  @include g.mq(md) {
    display: none;
  }
}

.p-top {
  height: 100svh;

  .p-top-wrap {
    display: flex;
    height: 100%;

    .p-top-img {
      flex: 0 0 680px;

      @media screen and (min-width:767px) and (max-width:1600px){
        flex: 0 0 g.px2vw(680,1600); 
      }
      @media screen and (min-width:767px) and (max-width:1200px){
        flex: 0 0 324px;
      }
      @media screen and (max-width:1024px){
        display: none;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .p-top-content {
      position: relative;
      flex: 0 1 100%;

      .p-top-box {

        @include g.mq(md) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: calc(100svh - 210px);  
        }
        
        .p-top-list {

          @include g.mq(md) {
            display: flex;
            max-width: 780px;
            margin: 0 auto;       
            padding: 0 10px;
            column-gap: 20px;     
          }
          @media screen and (min-width: 1600px){
            max-width: 85%;
          }
          @include g.mq(sm) {
            margin-top: 30px;
            padding: 0 50px; 
          }
  
          .p-top-list__item {
            @include g.mq(md) {              
              width: calc((100% - 40px)/3);
            }
            &+.p-top-list__item {
              @include g.mq(sm) {
                margin-top: 12px;
              }
            }
            &:last-child {
              .p-top-list__message-txt {
                letter-spacing: 0.1em;
              }
            }
          }
          .p-top-list__message {
            position: relative;
            
            @include g.mq(md) {
              padding: 20px 10px;              
            }
            @include g.mq(sm) {
              padding: 13px 10px;
            }
  
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
              border-top: 1px solid #000;
              border-right: 1px solid #000;

              @include g.mq(md) {
                right: 10px;
                width: 10px;
                height: 10px;  
              }
              @include g.mq(sm) {
                right: 18px;
                width: 7px;
                height: 7px;
              }
            }
  
            .p-top-list__message-txt {
              letter-spacing: 0.4em;
              
              @include g.mq(md) {
                font-size: 20px;              
              }
              @include g.mq(sm) {
                display: flex;
                align-items: center;
                column-gap: 10px;
                font-size: 16px;
              }
  
              span {
                display: block;
                font-family: g.$font-lato;
                letter-spacing: 0.01em;

                @include g.mq(md) {
                  font-size: 10px;
                  margin-bottom: 10px;
                }
                @include g.mq(sm) {
                  order: 1;
                  font-size: 10px;
                }
              }
            }
          }
        }
  
        .p-top-banner {        

          @include g.mq(md) {
            display: flex;
            max-width: 780px;        
            margin: 40px auto 0;
            padding: 0 10px;
            column-gap: 20px;  
          }

          @include g.mq(sm) {
            padding: 0 20px;
            margin: 30px 0 24px;
          }

          .p-top-banner__item {
            transition: all .3s;

            &:hover {
              opacity: .7;
            }

            &.comming {
              position: relative;
              pointer-events: none;

              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                opacity: .85;
                mix-blend-mode: normal;
              }

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #6B6B6B;
                font-family: g.$font-lato;
                font-weight: 700;
                font-size: 20px;
              }
            }

            &+.p-top-banner__item {
              @include g.mq(sm) {
                margin-top: 20px;
              }
            }
          }
        }

      }
    }
  }
}