@use "../global" as g;

/* ==========================================================================
  animation
========================================================================== */
.p-top {
  .p-top-wrap {
    .p-top-content {
      .p-top-box {
        .p-top-list {
          .p-top-list__item {

            &:hover {
              .p-top-list__message {
                background-color: #F9F6F4;
              }
            }
          }
          .p-top-list__message {
            transition: all .3s;
          }
        }
        .p-top-banner {        
        }
      }
    }
  }
}