@use "../global" as g;

/* ==========================================================================
   utility
========================================================================== */

/* ---------------------------------------------------------------------------
  show/hide
  --------------------------------------------------------------------------- */
// mobile
.u-sp {
  display: initial;

  @include g.mq(md) {
    display: none;
  };
}

// desktop
.u-pc {
  display: none;

  @include g.mq(md) {
    display: initial;
  };
}