@use "../global" as g;

/* ==========================================================================
  header
========================================================================== */

/*# top
/*===========================================*/
.l-header {
  .l-header-wrap {
    display: flex;
    align-items: center;
    
    @include g.mq(md) {
      height: 160px;
      border-bottom: 1px solid g.$col-black;
    }
    @include g.mq(sm) {
      height: 137px;
    }

    .l-header-logo {
      width: 100%;
      height: 100%;
      background-color: g.$col-black;

      @include g.mq(md) {
        display: flex;
        align-items: center;  
        flex: 0 1 100%;
        column-gap: 16px;
        padding: 0 30px 0 60px;
      }
      @media screen and (min-width:767px) and (max-width:1200px){
        padding: 0 30px;
      }
      @include g.mq(sm) {
        padding: 22px 0 20px;
      }

      .l-header-logo__img {
        @include g.mq(md) {          
          flex: 0 0 140px; 
        }
        @include g.mq(sm) {
          width: 155px;
          margin: 0 auto;
        }
      
      }
      .l-header-logo__txt {
        flex: 0 1 100%;
        color: #fff;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.005em;

        @include g.mq(sm) {
          margin-top: 10px;
          font-size: 15px;
          text-align: center;
        }
      }
    }
    .l-header-menu {
      flex: 0 0 340px;
      width: 100%;
      height: 100%;

      @include g.mq(sm) {
        display: none;
      }
    }
  }
}


/*# 下層
/*===========================================*/
.l-u_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;

  .l-u_header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @include g.mq(md) {
      height: 100px;
      padding: 0 40px;  
    }
    @include g.mq(sm) {
      height: 50px;
      padding: 0 8px 0 20px;
    }
  }
  .l-u_header-logo {
    z-index: 11 ;
    @include g.mq(md) {      
      flex: 0 0 127px;
    }
    @include g.mq(sm) {
      flex: 0 0 69px;
    }
  }
  .l-u_header-nav {
    flex: 0 1 100%;

    @include g.mq(md) {
      padding-right: 42px;
    }
    @media screen and (max-width:1024px){
      display: none;
    }
  }
  .l-u_header-list {
    display: flex;
    justify-content: flex-end;
    column-gap: 37px;

    .l-u_header-list__item {
      position: relative;
      font-size: 14px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 1px;
        background-color: g.$col-black;
        opacity: 0;
        transition: all .3s;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }

      &.comming {
        opacity: .4;
        pointer-events: none;
      }
    }
  }
  .l-u_header-menu {
    position: relative;
    width: 44px;
    height: 44px;
    z-index: 11;
    cursor: pointer;

    @media screen and (min-width:1024px){
      display: none;
    }

    span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 2px;
      background-color: g.$col-black;
      transition: all  .3s;

      &:first-child {
        top: 32%;
      }
      &:nth-child(2n) {
        top: 50%;
        transform: translate(-50%,-50%);
      }
      &:last-child {
        bottom: 32%;
      }
    }

    &.open {

      span {
        width: 28px;
        
        &:first-child {
          top: 49%;
          transform: translate(-50%,-50%) rotate(45deg);
        }
        &:nth-child(2n) {
          display: none;
        }
        &:last-child {
          bottom: 46%;
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
  }
}

.l-humberger {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100svh;
  padding: 0 20px 75px; 
  background-color: #fff;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;


  &.active {
    top: 0;
    visibility: visible;
    opacity: 1;
  }

  .l-humberger-wrap {
    overflow: auto;
    margin-top: 50px;
    height: calc(100svh - g.px2vw(120));
  }

  .l-humberger-menu {
    padding-top: g.px2vw(30);

    .c-menu__list {
      margin-top: 0;
    }
    .c-menu__item {
      font-size: 18px;
    }
  }

  .l-humberger-list {
    margin-top: 27px;

    .l-humberger-list__item {
      
      a {
        display: block;
        position: relative;
        padding: 30px 20px;
        font-weight: 500;
        font-size: 18px;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          border-top: 1px solid #000;
          border-right: 1px solid #000;

          @include g.mq(sm) {
            right: 30px;
            width: 7px;
            height: 7px;
          }
        }

        span {
          display: block;
          font-family: g.$font-lato;
          font-size: 12px;
          margin-top: 9px;
        }
      }
    }
  }

  .l-humberger-banner {
    margin-top: 36px;

    .l-humberger-banner__item {
      &+.l-humberger-banner__item {
        margin-top: 20px;
      }
      &.comming {
        position: relative;
        pointer-events: none;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          opacity: .85;
          mix-blend-mode: normal;
        }

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          color: #6B6B6B;
          font-family: g.$font-lato;
          font-weight: 700;

          @include g.mq(sm) {            
            font-size: g.px2vw(18);
          }
        }
      }
    }
  }
  .l-footer {    
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}