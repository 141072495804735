@use "../global" as g;
/**/
/* ==========================================================================
   base
========================================================================== */

/*------------------------------------------------------------
	レイアウト
------------------------------------------------------------*/
html {
	font-size: 62.5%;
}

body {
	min-width: 320px;
	width: 100%;
	color: g.$col-black;
	line-height: 1.8;
	font-size: 16px;
	font-family: g.$font-sans;
	font-weight: 400;
	text-size-adjust: none;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
}

.l-under {
	background-image: url("../img/cmn/under-bg01.png");
	background-size: contain;
	background-repeat: repeat;

	@include g.mq(md) {
		padding-top: 100px;
	}
	@include g.mq(sm) {
		padding-top: 50px;
	}
}
img {
	vertical-align: middle;
	width: 100%;
}
a {
  transition: all 1s;
}