@use "../global" as g;

/* --------------------------------------------------------------------------
  container
-------------------------------------------------------------------------- */
%c-container {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height:100%;
}

.c-container {
  @extend %c-container;
  max-width:inherit;
  padding:0 20px;

  @include g.mq(md) {
    max-width: 1400px + 40;
    padding:0 20px;
  }
}

.c-container-960px {
  @extend %c-container;

  @include g.mq(md) {
    max-width: 960px + 80px;
    padding: 0 40px;
  }
  @include g.mq(sm) {
    padding: 0 20px;
  }
}
.c-container-1120px {
  @extend %c-container;

  @include g.mq(md) {
    max-width: 1120px + 80px;
    padding: 0 40px;
  }
  @include g.mq(sm) {
    padding: 0 20px;
  }
}

/*# 共通メニュー
/*===========================================*/
.c-menu__list {
  display: flex;
  align-items: center;
  justify-content: center;

  @include g.mq(md) {
    height: 100%;
    column-gap: 30px;  
  }
  @include g.mq(sm) {
    column-gap: 46px;
    margin-top: 30px;
  }

  .c-menu__item {
    position: relative;
    transition: all .3s;
    
    @include g.mq(md) {
      font-size: 14px;      
    }
    @include g.mq(sm) {
      position: relative;
      font-size: 16px;
      
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -21px;
        width: 1px;
        height: 13px;
        background-color: #000;
      }
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 100%;
      height: 1px;
      background-color: g.$col-black;
      opacity: 0;
      transition: all .3s;
    }

    &+.c-menu__item-instagram {
      width: 28px;

      @include g.mq(sm) {
        &::after {
          display: none;
        }
      }

      &:hover {
        opacity: .7;
      }
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
}

/*# 下層タイトル
/*===========================================*/
.c-under-title {
  position: relative;
  font-weight: bold;
  font-family: g.$font-lato;
  line-height: 1.5;
  text-align: center;

  @include g.mq(md) {
    font-size: 64px;
  }
  @include g.mq(sm) {
    font-size: 32px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: g.$col-accent;

    @include g.mq(md) {
      bottom: 35px;
      width: 60px;
      height: 3px;  
    }
    @include g.mq(sm) {
      bottom: 23px;
      width: 30px;
      height: 2px;
    }
  }
  span {
    display: block;
    font-family: g.$font-sans;
    font-weight: 500;
    line-height: 1.5;
    
    @include g.mq(md) {
      font-size: 20px;
    }
    @include g.mq(sm) {
      font-size: 14px;
    }
  }
}

.c-fadeup {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity .8s, transform .8s;

  &.is-inview {
    opacity: 1; 
    transform: translateY(0);
    transition-delay: .5s; 
  }

}
