/* ==========================================================================
   variables
========================================================================== */

// color
$col-accent: #B50000;
$col-black: #2d2d2d;
$col-white: #fff;


// font
$font-sans: "Noto Sans JP", system-ui;
$font-lato: "Lato", sans-serif;

